import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { PackageMetadata } from "@happeo/docs-tools";
import TranslationProvider from "../../../src/gatsby-theme-docz/TranslationProvider";
import pkg from "../package.json";
import { FilePicker, Dropzone } from "../src/index";
import ExampleInnerDropzone from "../src/examples/ExampleInnerDropzone";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "file-picker"
    }}>{`File-picker`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <h2 {...{
      "id": "file-picker-1"
    }}>{`File picker`}</h2>
    <Playground __position={1} __code={'<FilePicker onSelected={file => console.log(file)} />\n<FilePicker onSelected={file => console.log(file)} text={\'some text\'} />\n<FilePicker onSelected={file => console.log(file)} disabled />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      TranslationProvider,
      pkg,
      FilePicker,
      Dropzone,
      ExampleInnerDropzone,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FilePicker onSelected={file => console.log(file)} mdxType="FilePicker" />
  <FilePicker onSelected={file => console.log(file)} text={"some text"} mdxType="FilePicker" />
  <FilePicker onSelected={file => console.log(file)} disabled mdxType="FilePicker" />
    </Playground>
    <Props of={FilePicker} mdxType="Props" />
    <h2 {...{
      "id": "dropzone"
    }}>{`Dropzone`}</h2>
    <h3 {...{
      "id": "dropzone-1"
    }}>{`Dropzone`}</h3>
    <p>{`Dropzone always returns an array on the onSelect callback. Important: If you have added inputProps multiple:false, and user drag-drops
more than 1 file, all dropped files will be returned. You need to select the first one on the list if necessary. This is to retain the
fileList prototype.`}</p>
    <Playground __position={3} __code={'<TranslationProvider>\n  <Dropzone onSelected={file => console.log(file)} />\n</TranslationProvider>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      TranslationProvider,
      pkg,
      FilePicker,
      Dropzone,
      ExampleInnerDropzone,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <TranslationProvider mdxType="TranslationProvider">
    <Dropzone onSelected={file => console.log(file)} mdxType="Dropzone" />
  </TranslationProvider>
    </Playground>
    <h3 {...{
      "id": "dropzone-with-custom-main-text-and-custom-input-props"
    }}>{`Dropzone with custom main text and custom input props`}</h3>
    <Playground __position={4} __code={'<TranslationProvider>\n  <Dropzone\n    onSelected={file => console.log(file)}\n    text={\'some text\'}\n    inputProps={{ multiple: false, accept: \'image/*,.pdf\' }}\n  />\n</TranslationProvider>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      TranslationProvider,
      pkg,
      FilePicker,
      Dropzone,
      ExampleInnerDropzone,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <TranslationProvider mdxType="TranslationProvider">
    <Dropzone onSelected={file => console.log(file)} text={"some text"} inputProps={{
          multiple: false,
          accept: "image/*,.pdf"
        }} mdxType="Dropzone" />
  </TranslationProvider>
    </Playground>
    <h3 {...{
      "id": "dropzone-with-custom-children"
    }}>{`Dropzone with custom children`}</h3>
    <p>{`The following attributes are injected into the child:`}</p>
    <ul>
      <li parentName="ul">{`clickUpload -fn`}</li>
      <li parentName="ul">{`isDragging -bool`}</li>
      <li parentName="ul">{`isDragActive -bool`}</li>
      <li parentName="ul">{`disabled -bool`}</li>
    </ul>
    <Playground __position={5} __code={'<TranslationProvider>\n  <Dropzone onSelected={file => console.log(file)}>\n    <ExampleInnerDropzone />\n  </Dropzone>\n</TranslationProvider>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      TranslationProvider,
      pkg,
      FilePicker,
      Dropzone,
      ExampleInnerDropzone,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <TranslationProvider mdxType="TranslationProvider">
    <Dropzone onSelected={file => console.log(file)} mdxType="Dropzone"><ExampleInnerDropzone mdxType="ExampleInnerDropzone" /></Dropzone>
  </TranslationProvider>
    </Playground>
    <h3 {...{
      "id": "dropzone-that-is-disabled"
    }}>{`Dropzone that is disabled`}</h3>
    <Playground __position={6} __code={'<TranslationProvider>\n  <Dropzone onSelected={file => console.log(file)} disabled />\n</TranslationProvider>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      TranslationProvider,
      pkg,
      FilePicker,
      Dropzone,
      ExampleInnerDropzone,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <TranslationProvider mdxType="TranslationProvider">
    <Dropzone onSelected={file => console.log(file)} disabled mdxType="Dropzone" />
  </TranslationProvider>
    </Playground>
    <Props of={Dropzone} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      